import { useState, useEffect, FC, MutableRefObject } from 'react'
import * as yup from 'yup'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  LinearProgress,
  Modal,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import {
  FormBoxStyle,
  NotificacaoAlerta,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import {
  VForm,
  useVForm,
  VTextField,
  VTextArea,
  VSwitch,
} from '../../../../shared/forms'
import {
  IDefaultReplie,
  createDefaultReply,
  updateDefaultReplyById,
} from '../../../../shared/services/api'
import { useAuthContext } from '../../../../shared/contexts'
import formValidationSchema, { IFormdata } from './validate'
import { ModalHeader } from '../../../../shared/components/modal/Header'

interface IFormulario {
  show: boolean
  create: boolean
  data?: IDefaultReplie
  onClose: () => void
  update: MutableRefObject<boolean>
}

export const Formulario: FC<IFormulario> = ({
  data,
  show,
  create,
  update,
  onClose,
}) => {
  const { user } = useAuthContext()

  const [formTitle, setFormTitle] = useState('')
  const { formRef } = useVForm()

  const [isLoading, setIsLoading] = useState(false)

  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const handleSubmit = (formData: IFormdata) => {
    setIsLoading(true)
    let institution: number
    if (user?.modules[0].profile === 0) {
      institution = Number(localStorage.getItem('ADM_SELECTED_INSTITUTION'))
      if (isNaN(institution))
        return NotificacaoAlerta('Selecione uma instituição')
    } else {
      institution = Number(user?.institution_id)
    }
    formData.institution = institution
    formValidationSchema
      .validate(formData, { abortEarly: false })
      .then((validatedData) => {
        if (create) {
          createDefaultReply(validatedData).then((result) => {
            setIsLoading(false)
            if (result instanceof Error) {
              NotificacaoError(result.message)
            } else {
              NotificacaoSucesso('Resposta pré definida com sucesso')
              update.current = true
              formRef.current?.reset()
              onClose()
            }
          })
        } else {
          updateDefaultReplyById(Number(data?.id), validatedData).then(
            (result) => {
              setIsLoading(false)
              if (result instanceof Error) {
                NotificacaoError(result.message)
              } else {
                NotificacaoSucesso('Resposta atualizada com sucesso')
                update.current = true
                formRef.current?.reset()
                onClose()
              }
            },
          )
        }
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false)
        const validationErrors: { [key: string]: string } = {}

        errors.inner.forEach((error) => {
          if (!error.path) return

          validationErrors[error.path] = error.message
        })
        formRef.current?.setErrors(validationErrors)
      })
  }

  useEffect(() => {
    if (!create && data !== undefined)
      setFormTitle(`Editar Resposta Pré Definida`)
    else setFormTitle('Nova Resposta Pré Definida')
  }, [create, data, show])

  return (
    <Modal onClose={onClose} open={show} aria-describedby="modal-title">
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        width={
          xsDown
            ? theme.spacing(30)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader onClose={onClose} showCloseButton title={formTitle} />

        <Divider />
        {isLoading && (
          <LinearProgress
            variant="indeterminate"
            sx={{ height: '5px', borderRadius: '50px', marginY: 2 }}
          />
        )}
        <Box margin={1}>
          <VForm
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={create ? undefined : data}
          >
            <Grid container direction="column" spacing={2} paddingBottom={2}>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="title"
                    label="Título"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                  />
                </Grid>
                <Grid item xs={12}>
                  <VTextArea
                    name="text"
                    minRows={4}
                    maxRows={8}
                    placeholder="Insira aqui o texto da resposta"
                    ariaLabel="Texto"
                    style={{
                      width: '100%',
                      font: 'message-box',
                      borderRadius: '5px',
                      fontVariant: 'historical-forms',
                    }}
                  />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center">
                  <FormControlLabel
                    control={<VSwitch name="status" />}
                    label="Status"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box display="flex" flex={1} justifyContent="center" gap={1}>
              <Button type="submit" variant="contained" disableElevation>
                <Icon>save</Icon>
                Salvar
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  formRef.current?.reset()
                  onClose()
                }}
              >
                cancelar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}
