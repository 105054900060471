// Global Imports
import { ReactNode } from 'react'
import { Navigate, Route } from 'react-router-dom'

// Local Imports
import {
  // General Pages
  Perfil,
  LoginPage,
  NotFoundPage,

  // ADM Page
  Icones,
  Cidades,
  Estados,
  Clientes,
  Migracoes,
  DetalhesIcone,
  DetalhesCidade,
  DetalhesEstado,
  Administradores,
  DetalhesCliente,
  RamoDeAtividades,
  DetalhesAdministrador,
  DetalhesRamoDeAtividades,

  // Ombudsman Pages
  Slides,
  Setores,
  Assuntos,
  Perguntas,
  Legislacao,
  Protocolos,
  Tipologias,
  Realatorios,
  Agrupamentos,
  PreRespostas,
  DetalhesSetores,
  DetalhesAssuntos,
  UsuariosExternos,
  DetalhesProtocolo,
  DetalhesTipologia,
  EditarInstituicao,
  UsuariosInternos,
  OmbudsmanDashboard,
  DetalhesAgrupamento,
  DetalhesUsuarioExterno,
  DetalhesUsuarioInterno,

  // Internal Comunication Pages
  CIDashboard,

  // Customer Service Pages
  Perfis,
  SlideSAC,
  AssuntoSAC,
  ModulosSac,
  SetoresSAC,
  PerguntaSAC,
  CategoriaSAC,
  SacDashboard,
  AgrupamentoSAC,
  DetalhesPerfil,
  DetalhesModuloSac,
  DetalhesAssuntoSAC,
  DetalhesSetoresSAC,
  DetalhesCategoriaSAC,
  DetalhesAgrupamentoSAC,
  RespostasPreDefinidaSAC,
  UsuarioExternoSAC,
} from '../pages'
import { ProtocoloSAC } from '../pages/sac/protocolos/Protocolos'
import { DetalhesProtocoloSAC } from '../pages/sac/protocolos/DetalhesProtocolo'
import { DetalhesUsuarioExternoSAC } from '../pages/sac/usuarios-externos/DetalhesUsuarioExterno'
import { ConfirmarUsuario } from '../pages/usuario-externo/confirmar-usuario/ConfirmarUsuario'

interface IRoutes {
  path: string
  element?: ReactNode
}

export const routes: IRoutes[] = [
  // Generic Routes
  {
    path: '/admin',
    element: <LoginPage />,
  },
  {
    path: '/pagina_404',
    element: <NotFoundPage />,
  },
  {
    path: '*',
  },
  {
    path: '/external_users/confirmation',
    element: <ConfirmarUsuario />,
  },

  // Ombudsman Routes
  {
    path: '/ombudsman/:title/dashboard',
    element: <OmbudsmanDashboard />,
  },
  {
    path: '/ombudsman/:title/clients',
    element: <Clientes />,
  },
  {
    path: '/ombudsman/:title/clients/detail/:id',
    element: <DetalhesCliente />,
  },
  {
    path: '/ombudsman/:title/managers',
    element: <Administradores />,
  },
  {
    path: '/ombudsman/:title/managers/detail/:id',
    element: <DetalhesAdministrador />,
  },
  {
    path: '/ombudsman/:title/workfields',
    element: <RamoDeAtividades />,
  },
  {
    path: '/ombudsman/:title/workfields/detail/:id',
    element: <DetalhesRamoDeAtividades />,
  },
  {
    path: '/ombudsman/:title/states',
    element: <Estados />,
  },
  {
    path: '/ombudsman/:title/states/detail/:id',
    element: <DetalhesEstado />,
  },
  {
    path: '/ombudsman/:title/cities',
    element: <Cidades />,
  },
  {
    path: '/ombudsman/:title/cities/detail/:id',
    element: <DetalhesCidade />,
  },
  {
    path: '/ombudsman/:title/icons',
    element: <Icones />,
  },
  {
    path: '/ombudsman/:title/icons/detail/:id',
    element: <DetalhesIcone />,
  },
  {
    path: '/ombudsman/:title/groupings',
    element: <Agrupamentos />,
  },
  {
    path: '/ombudsman/:title/groupings/detail/:id',
    element: <DetalhesAgrupamento />,
  },
  {
    path: '/ombudsman/:title/sectors',
    element: <Setores />,
  },
  {
    path: '/ombudsman/:title/sectors/detail/:id',
    element: <DetalhesSetores />,
  },
  {
    path: '/ombudsman/:title/typologies',
    element: <Tipologias />,
  },
  {
    path: '/ombudsman/:title/typologies/detail/:id',
    element: <DetalhesTipologia />,
  },
  {
    path: '/ombudsman/:title/subjects',
    element: <Assuntos />,
  },
  {
    path: '/ombudsman/:title/subjects/detail/:id',
    element: <DetalhesAssuntos />,
  },
  {
    path: '/ombudsman/:title/sectorsusers',
    element: <UsuariosInternos />,
  },
  {
    path: '/ombudsman/:title/sectorsusers/detail/:id',
    element: <DetalhesUsuarioInterno />,
  },
  {
    path: '/ombudsman/:title/questions',
    element: <Perguntas />,
  },
  {
    path: '/ombudsman/:title/managerinstitution',
    element: <EditarInstituicao />,
  },
  {
    path: '/ombudsman/:title/protocols',
    element: <Protocolos />,
  },
  {
    path: '/ombudsman/:title/protocols/detail/:id',
    element: <DetalhesProtocolo />,
  },
  {
    path: '/ombudsman/:title/slides',
    element: <Slides />,
  },
  {
    path: '/ombudsman/:title/externalusers',
    element: <UsuariosExternos />,
  },
  {
    path: '/ombudsman/:title/externalusers/detail/:id',
    element: <DetalhesUsuarioExterno />,
  },
  {
    path: '/ombudsman/:title/legilsations',
    element: <Legislacao />,
  },
  {
    path: '/ombudsman/:title/reports',
    element: <Realatorios />,
  },
  {
    path: '/ombudsman/:title/profile',
    element: <Perfil />,
  },
  {
    path: '/ombudsman/:title/migrations',
    element: <Migracoes />,
  },
  {
    path: '/ombudsman/:title/default_replies',
    element: <PreRespostas />,
  },

  // CI Routes
  {
    path: '/ci/:title/dashboard',
    element: <CIDashboard />,
  },
  {
    path: '/ci/:title/managers',
    element: <Administradores />,
  },
  {
    path: '/ci/:title/managers/detail/:id',
    element: <DetalhesAdministrador />,
  },
  {
    path: '/ci/:title/profile',
    element: <Perfil />,
  },
  {
    path: '/ci/:title/migrations',
    element: <Migracoes />,
  },

  // S.A.C Routes
  {
    path: '/customer-service/:title/dashboard',
    element: <SacDashboard />,
  },
  {
    path: '/customer-service/:title/clients',
    element: <Clientes />,
  },
  {
    path: '/customer-service/:title/modules',
    element: <ModulosSac />,
  },
  {
    path: '/customer-service/:title/modules/details/:id',
    element: <DetalhesModuloSac />,
  },
  {
    path: '/customer-service/:title/managers',
    element: <Administradores />,
  },
  {
    path: '/customer-service/:title/icons',
    element: <Icones />,
  },
  {
    path: '/customer-service/:title/workfields',
    element: <RamoDeAtividades />,
  },
  {
    path: '/customer-service/:title/users',
    element: <Perfis />,
  },
  {
    path: '/customer-service/:title/users/details/:id',
    element: <DetalhesPerfil />,
  },
  {
    path: '/customer-service/:title/profile',
    element: <Perfil />,
  },
  {
    path: '/customer-service/:title/groupings',
    element: <AgrupamentoSAC />,
  },
  {
    path: '/customer-service/:title/groupings/details/:id',
    element: <DetalhesAgrupamentoSAC />,
  },
  {
    path: '/customer-service/:title/sectors',
    element: <SetoresSAC />,
  },
  {
    path: '/customer-service/:title/sectors/details/:id',
    element: <DetalhesSetoresSAC />,
  },
  {
    path: '/customer-service/:title/subjects',
    element: <AssuntoSAC />,
  },
  {
    path: '/customer-service/:title/subjects/details/:id',
    element: <DetalhesAssuntoSAC />,
  },
  {
    path: '/customer-service/:title/categories',
    element: <CategoriaSAC />,
  },
  {
    path: '/customer-service/:title/categories/details/:id',
    element: <DetalhesCategoriaSAC />,
  },
  {
    path: '/customer-service/:title/protocols',
    element: <ProtocoloSAC />,
  },
  {
    path: '/customer-service/:title/protocols/details/:id',
    element: <DetalhesProtocoloSAC />,
  },
  {
    path: '/customer-service/:title/questions',
    element: <PerguntaSAC />,
  },
  {
    path: '/customer-service/:title/slides',
    element: <SlideSAC />,
  },
  {
    path: 'customer-service/:title/default_replies',
    element: <RespostasPreDefinidaSAC />,
  },
  {
    path: 'customer-service/:title/externalusers',
    element: <UsuarioExternoSAC />,
  },
  {
    path: 'customer-service/:title/externalusers/details/:id',
    element: <DetalhesUsuarioExternoSAC />,
  },
]

export const PrivateRoutes = routes.map((route, index) => (
  <Route
    key={index}
    path={route.path}
    element={
      route.element ? route.element : <Navigate to="/pagina_404" replace />
    }
  />
))

export const RedirectPrivateRoutes = routes.map((route, index) => (
  <Route
    key={index}
    path={route.path}
    element={
      route.element ? <LoginPage /> : <Navigate to="/pagina_404" replace />
    }
  />
))
