import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { IModule, useAuthContext } from './AuthContext'

interface moduleContextData {
  currentModule: string | undefined
  moduleTitle: string | undefined

  thisModule: IModule | undefined

  setModule: (e: string) => void
  setTitle: (e: string) => void
}

interface moduleProviderProps {
  children: ReactNode
}

const ModuleContext = createContext({} as moduleContextData)

export const ModuleProvider: FC<moduleProviderProps> = ({ children }) => {
  const { user } = useAuthContext()

  const [title, setTitle] = useState<string>()
  const [module, setModule] = useState<string>()
  const [tModule, setTModule] = useState<IModule>()

  const currentModule = useMemo(() => {
    return module
  }, [module])

  const moduleTitle = useMemo(() => {
    return title
  }, [title])

  const thisModule = useMemo(() => {
    return tModule
  }, [tModule])

  const handleSetModule = useCallback((value: string) => {
    setModule(value)
  }, [])

  const handleSetTitle = useCallback((value: string) => {
    setTitle(value)
  }, [])

  useEffect(() => {
    if (thisModule && thisModule.profile !== 0) {
      localStorage.removeItem('ADM_SELECTED_INSTITUTION')
    }
  }, [thisModule])

  useEffect(() => {
    setTModule(
      user?.modules.find(
        (mdl) => mdl.type === currentModule && mdl.title === moduleTitle,
      ),
    )
  }, [user?.modules, currentModule, moduleTitle])

  return (
    <ModuleContext.Provider
      value={{
        currentModule,
        setModule: handleSetModule,
        moduleTitle,
        setTitle: handleSetTitle,
        thisModule,
      }}
    >
      {children}
    </ModuleContext.Provider>
  )
}

export const useModuleContext = () => useContext(ModuleContext)
