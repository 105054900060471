/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, MutableRefObject } from 'react'
import * as yup from 'yup'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  AddCircle,
  Delete,
  PhotoCamera,
  RemoveCircle,
} from '@mui/icons-material'
import { formatToCNPJ, isCNPJ } from 'brazilian-values'

import {
  IClientDetail,
  createClient,
  updateClientById,
} from '../../../../shared/services/api'
import {
  AutoCompleteCidades,
  AutoCompleteEstados,
  AutoCompleteRamos,
} from '../components/AutoComplete'
import {
  VForm,
  VSelect,
  VSwitch,
  VTextField,
  useVForm,
} from '../../../../shared/forms'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoPromise,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { MultiSelect } from '../components/MultiSelect'
import { toast } from 'react-toastify'
import {
  formatBRLCustom,
  formatCellPhone,
  formatPhone,
} from '../../../../shared/utils'
import { Scope } from '@unform/core'

interface IFormData {
  cnpj: string
}

const formValidationSchema: yup.Schema<IFormData> = yup.object({
  cnpj: yup
    .string()
    .required()
    .test('cnpj-validate', 'CNPJ inválido', (value) => isCNPJ(String(value))),
})

export const FormCliente = (
  onClose: () => void,
  show = false,
  update: MutableRefObject<boolean>,
  create: boolean,
  data?: IClientDetail,
) => {
  const theme = useTheme()

  const [formTitle, setFormTitle] = useState('')
  const { formRef } = useVForm()

  const [isLoading, setIsLoading] = useState(false)

  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const [logo, setLogo] = useState<any>(undefined)
  const [logoUrl, setLogoUrl] = useState('')
  const [clientType, setClientType] = useState<number>(1)
  const formLogo = new FormData()

  const [phones, setPhones] = useState<number | undefined>(1)
  const [cellPhones, setCellPhones] = useState<number | undefined>(1)

  const [modules, setModules] = useState<Array<unknown>>([])
  const [coverage, setCoverage] = useState(data?.coverage || 1)
  const [state, setState] = useState<number | ''>('')
  const [sms, setSMS] = useState(data?.activate_sms || false)

  useEffect(() => {
    setLogoUrl(data?.logo || '')
    setModules(data?.modules || [])
    setClientType(data?.client_type || 1)
    if (data?.phones) {
      setPhones(data?.phones.length)
    }
    if (data?.cellphones) {
      setCellPhones(data?.cellphones.length)
    }
  }, [data])

  const notLogo = (functionLoading: (e: boolean) => void) => {
    functionLoading(false)
    NotificacaoError('Insira uma Logo!')
  }

  const handleSubmit = (formData: IClientDetail) => {
    setIsLoading(true)
    formData.ombudsman_price = String(formData.ombudsman_price)
      .replace(',', '')
      .replace('R$', '')
      .replace(' ', '')
      .replace('.', '')
    if (formData.ombudsman_price === 'undefined') {
      formData.ombudsman_price = '0'
    }
    if (formData.client_type === 1) formData.ombudsman_limit_days = 30
    formValidationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        create
          ? logo
            ? createClient(formData)
                .then((result) => {
                  if (logo) formLogo.append('logo', logo, logo?.name)
                  setIsLoading(false)
                  if (result instanceof Error) {
                    NotificacaoError(result.message)
                  } else {
                    update.current = true
                    NotificacaoSucesso('Cliente Criado com Sucesso')
                    NotificacaoPromise(
                      updateClientById(Number(result.id), formLogo),
                      'Enviando Logo do Clinte',
                      'Logo Adcionada com Sucesso',
                      'Erro ao Adcionar Logo',
                    )
                    formRef.current?.reset()
                    setLogoUrl('')
                    setCoverage(1)
                    setClientType(1)
                    setModules([])
                    setLogo(undefined)
                    setClientType(1)
                    onClose()
                  }
                })
                .catch(() => {
                  setIsLoading(false)
                })
            : notLogo(setIsLoading)
          : updateClientById(Number(data?.id), formData).then((result) => {
              setIsLoading(false)
              if (result instanceof Error) {
                NotificacaoError(result.message)
              } else {
                update.current = true
                NotificacaoSucesso('Cliente Atualizado com Sucesso')
                if (logo) {
                  formLogo.append('logo', logo, logo?.name)
                  NotificacaoPromise(
                    updateClientById(Number(data?.id), formLogo),
                    'Enviando Logo do Cliente',
                    'Logo Atualizada com Sucesso',
                    'Erro ao Atualizar a Logo',
                  )
                }
                formRef.current?.reset()
                setLogoUrl('')
                setCoverage(1)
                setLogo(undefined)
                setModules([])
                setClientType(1)
                setClientType(1)
                setClientType(1)
                onClose()
              }
            })
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false)
        const validationErros: { [key: string]: string } = {}

        errors.inner.forEach((error) => {
          if (!error.path) return

          validationErros[error.path] = error.message
        })
        formRef.current?.setErrors(validationErros)
      })
  }

  useEffect(() => {
    if (!create && data !== undefined)
      setFormTitle(`Editar Cliente ${data.name}`)
    else setFormTitle('Novo Cliente')
  }, [create, data, show])

  return (
    <Modal
      onClose={() => {
        onClose()
        formRef.current?.reset()
        setClientType(1)
        setCoverage(1)
        setLogo(undefined)
        setLogoUrl('')
        setState('')
        setModules([])
      }}
      open={show}
      aria-describedby="modal-title"
    >
      <Box
        overflow="auto"
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        maxHeight="85vh"
        width={
          xsDown
            ? theme.spacing(30)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <Box display="flex" justifyContent="center" alignContent="center">
          <Typography
            variant="h6"
            component="h2"
            id="modal-title"
            textOverflow="ellipsis"
            noWrap
          >
            {formTitle}
          </Typography>
        </Box>

        <Divider />
        {isLoading && (
          <LinearProgress
            variant="indeterminate"
            sx={{ height: '5px', borderRadius: '50px' }}
          />
        )}
        <Box margin={1}>
          <VForm
            ref={formRef}
            onSubmit={(formData: IClientDetail) => handleSubmit(formData)}
            initialData={create ? { ombudsman_price: 0.1 } : data}
          >
            {/* Primeiro Grid do Formulário */}
            <Grid
              container
              display="flex"
              direction="column"
              justifyContent="center"
              alignItems="center"
              padding={smDown ? 0 : 2}
              spacing={2}
            >
              <Grid
                container
                item
                xs={12}
                display="flex"
                direction="column"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box maxWidth="80%" maxHeight="350vh">
                    <img
                      src={logo ? logoUrl : data?.logo}
                      width="100%"
                      height="80%"
                      alt="client_logo"
                    />
                  </Box>
                </Grid>
                <Grid item paddingX={2}>
                  <Button
                    variant="contained"
                    disabled={!!logo}
                    component="label"
                    endIcon={<PhotoCamera />}
                    disableElevation
                    sx={{ borderRadius: '50px' }}
                  >
                    Inserir Logo
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={(e) => {
                        if (e.target.files) {
                          if (!e.target.files[0].type.includes('image')) {
                            setLogo(undefined)
                            NotificacaoError('Tipo de Arquivo Não Suportado')
                            toast.info(
                              'Insira Arquivos de Imagem do Tipo PNG | JPG | JPEG',
                            )
                          } else {
                            setLogo(e.target.files[0])
                            setLogoUrl(URL.createObjectURL(e.target.files[0]))
                          }
                        } else {
                          URL.revokeObjectURL(logoUrl)
                          setLogo(undefined)
                          setLogoUrl('')
                        }
                      }}
                    />
                  </Button>
                  {!!logo && (
                    <IconButton
                      onClick={() => {
                        URL.revokeObjectURL(logoUrl)
                        setLogo(undefined)
                        setLogoUrl('')
                      }}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="name"
                    label="Nome"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} display="flex" spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="cnpj"
                    label="CNPJ"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                    formatData={formatToCNPJ}
                    inputProps={{ maxLength: 18 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="prefix"
                    formatData={(value) => value.replace(/[^A-Z-]/g, '')}
                    label="Sigla"
                    placeholder="SIGLA-OV-123456789"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                    inputProps={{ maxLength: 10 }}
                    upercase={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <AutoCompleteRamos
                    textStyle={{ fieldSet: { borderRadius: '50px' } }}
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="address"
                    label="Endereço"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MultiSelect
                    textStyle={{ fieldSet: { borderRadius: '50px' } }}
                    onChange={(e) => setModules(e.map((module) => module.id))}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <FormControlLabel
                  label="Status"
                  control={<VSwitch name="status" />}
                />
              </Grid>
              {(modules.includes('ombudsman') ||
                (data?.modules !== null
                  ? data?.modules.includes('ombudsman')
                  : false)) && (
                <Grid container item direction="column" paddingY={2} gap={2}>
                  <Divider />
                  <Typography variant="h5" component="h6">
                    Módulo de Ouvidoria
                  </Typography>
                  <Grid container item spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <VTextField
                        fullWidth
                        required
                        size="small"
                        name="ombudsman_title"
                        label="Título"
                        borderBottomLeft="50px"
                        borderTopLeft="50px"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <VTextField
                        fullWidth
                        required
                        size="small"
                        name="ombudsman_email"
                        type="email"
                        label="Email de Contato"
                        borderBottomLeft="50px"
                        borderTopLeft="50px"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item xs={12} sm={4} md={3}>
                      <VTextField
                        fullWidth
                        required
                        size="small"
                        name="slug"
                        label="Slug"
                        borderBottomLeft="50px"
                        borderTopLeft="50px"
                        lowercase={true}
                        inputProps={{ maxLength: 10 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} md={5}>
                      <VTextField
                        fullWidth
                        required
                        size="small"
                        name="contact_name"
                        label="Ouvidor Responsável"
                        borderBottomLeft="50px"
                        borderTopLeft="50px"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <VTextField
                        fullWidth
                        required
                        size="small"
                        name="working_hour"
                        label="Horário de Funcionamento"
                        borderBottomLeft="50px"
                        borderTopLeft="50px"
                      />
                    </Grid>
                  </Grid>

                  <Grid container item spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="plan-type-label">
                          Tipo de Plano
                        </InputLabel>
                        <VSelect
                          fullWidth
                          required
                          size="small"
                          name="ombudsman_plan"
                          label="Tipo de Plano"
                          labelId="plan-type-label"
                          sx={{ borderRadius: '50px' }}
                        >
                          <MenuItem value={1}>Gratuito</MenuItem>
                          <MenuItem value={2}>Pago</MenuItem>
                        </VSelect>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="client-type-label">
                          Tipo de Cliente
                        </InputLabel>
                        <VSelect
                          fullWidth
                          required
                          size="small"
                          name="client_type"
                          label="Tipo de Cliente"
                          labelId="client-type-label"
                          myChange={(e) => setClientType(e)}
                          sx={{ borderRadius: '50px' }}
                        >
                          <MenuItem value={1}>Público</MenuItem>
                          <MenuItem value={2}>Privado</MenuItem>
                        </VSelect>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <VTextField
                        fullWidth
                        required
                        type="date"
                        size="small"
                        name="ombudsman_expires_at"
                        label="Expira em"
                        InputLabelProps={{ shrink: true }}
                        borderBottomLeft="50px"
                        borderTopLeft="50px"
                      />
                    </Grid>
                  </Grid>

                  <Grid container item spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="coverage-label">Abrangência</InputLabel>
                        <VSelect
                          fullWidth
                          required
                          size="small"
                          name="coverage"
                          label="Abrangência"
                          labelId="coverage-label"
                          sx={{ borderRadius: '50px' }}
                          myChange={(e) => setCoverage(Number(e))}
                        >
                          <MenuItem value={1}>Nacional</MenuItem>
                          <MenuItem value={2}>Estadual</MenuItem>
                          <MenuItem value={3}>Municipal</MenuItem>
                        </VSelect>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <VTextField
                        fullWidth
                        required
                        size="small"
                        name="ombudsman_limit_days"
                        type="number"
                        disabled={clientType === 1}
                        label="Dias Corridos"
                        borderBottomLeft="50px"
                        borderTopLeft="50px"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <VTextField
                        fullWidth
                        required
                        size="small"
                        name="ombudsman_price"
                        label="Valor Cobrado"
                        type="text"
                        formatData={formatBRLCustom}
                        inputProps={{
                          maxLength: 13,
                        }}
                        borderBottomLeft="50px"
                        borderTopLeft="50px"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    {(coverage > 1 ||
                      (data?.coverage !== undefined && data?.coverage > 1)) && (
                      <>
                        <Grid item xs={12} sm={6}>
                          {/* Estados */}
                          <AutoCompleteEstados
                            textStyle={{ fieldSet: { borderRadius: '50px' } }}
                          />
                        </Grid>
                        {(coverage === 3 ||
                          (data?.coverage !== undefined &&
                            data?.coverage === 3)) && (
                          <Grid item xs={12} sm={6}>
                            {/* Cidades */}
                            <AutoCompleteCidades
                              state_id={String(state)}
                              textStyle={{ fieldSet: { borderRadius: '50px' } }}
                            />
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid container item>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      display="flex"
                      justifyContent="center"
                    >
                      <FormControlLabel
                        label="Liberar manifestações anônimas"
                        control={<VSwitch name="anonymous" />}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      display="flex"
                      justifyContent="center"
                    >
                      <FormControlLabel
                        label="Liberar Informações Gerais"
                        control={<VSwitch name="general_informations" />}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      display="flex"
                      justifyContent="center"
                    >
                      <FormControlLabel
                        label="Liberar Dados Estatísticos"
                        control={<VSwitch name="reports" />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <VTextField
                        fullWidth
                        label="Separador"
                        name="separator_ov"
                        borderBottomLeft="50px"
                        borderTopLeft="50px"
                        size="small"
                        inputProps={{
                          maxLength: 2,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    spacing={2}
                    display="flex"
                    alignItems="center"
                    direction="column"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      width={smDown ? '99%' : '66%'}
                      borderRadius="10px"
                      padding={2}
                      gap={1}
                      alignItems="center"
                    >
                      <Typography variant="h4" textAlign="center">
                        Telefones Fixos
                      </Typography>
                      <Grid
                        container
                        item
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        direction="column"
                        spacing={1}
                      >
                        <Grid container item xs={12} justifyContent="center">
                          <Scope path="phones[0]">
                            <Grid item xs={11} sm={phones === 1 ? 4 : 5}>
                              <VTextField
                                fullWidth
                                size="small"
                                name="title"
                                label="Nome"
                                disabled={isLoading}
                                borderBottomLeft="50px"
                                borderTopLeft="50px"
                                borderBottomRight="0px"
                                borderTopRight="0px"
                                inputProps={{ maxLength: 10 }}
                              />
                            </Grid>
                            <Grid item xs={11} sm={7}>
                              <VTextField
                                fullWidth
                                size="small"
                                name="number"
                                label="Telfone Fixo"
                                placeholder="(DDD) 1234-5678"
                                disabled={isLoading}
                                borderBottomLeft="0px"
                                borderTopLeft="0px"
                                formatData={formatPhone}
                                inputProps={{ maxLength: 14 }}
                              />
                            </Grid>
                            {phones === 1 && (
                              <Grid
                                item
                                xs={1}
                                display="flex"
                                justifyContent="center"
                              >
                                <Tooltip title="adcionar telefone">
                                  <IconButton
                                    onClick={() => setPhones(phones + 1)}
                                  >
                                    <AddCircle color="success" />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            )}
                          </Scope>
                        </Grid>
                        {Number(phones) >= 2 && (
                          <Grid container item xs={12} justifyContent="center">
                            <Scope path="phones[1]">
                              <Grid item xs={11} sm={phones === 2 ? 4 : 5}>
                                <VTextField
                                  fullWidth
                                  required
                                  size="small"
                                  name="title"
                                  label="Nome"
                                  disabled={isLoading}
                                  borderBottomLeft="50px"
                                  borderTopLeft="50px"
                                  borderBottomRight="0px"
                                  borderTopRight="0px"
                                  inputProps={{ maxLength: 10 }}
                                />
                              </Grid>
                              <Grid item xs={11} sm={7}>
                                <VTextField
                                  fullWidth
                                  required
                                  size="small"
                                  name="number"
                                  label="Telfone Fixo"
                                  placeholder="(DDD) 1234-5678"
                                  disabled={isLoading}
                                  borderBottomLeft="0px"
                                  borderTopLeft="0px"
                                  formatData={formatPhone}
                                  inputProps={{ maxLength: 14 }}
                                />
                              </Grid>
                              {phones === 2 && (
                                <Box display="flex" flexDirection="row">
                                  <Tooltip title="adcionar telefone">
                                    <IconButton
                                      onClick={() => setPhones(phones + 1)}
                                    >
                                      <AddCircle color="success" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="remover telefone">
                                    <IconButton
                                      onClick={() => setPhones(phones - 1)}
                                    >
                                      <RemoveCircle color="error" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              )}
                            </Scope>
                          </Grid>
                        )}
                        {Number(phones) >= 3 && (
                          <Grid container item xs={12} justifyContent="center">
                            <Scope path="phones[2]">
                              <Grid item xs={11} sm={phones === 3 ? 4 : 5}>
                                <VTextField
                                  fullWidth
                                  required
                                  size="small"
                                  name="title"
                                  label="Nome"
                                  disabled={isLoading}
                                  borderBottomLeft="50px"
                                  borderTopLeft="50px"
                                  borderBottomRight="0px"
                                  borderTopRight="0px"
                                  inputProps={{ maxLength: 10 }}
                                />
                              </Grid>
                              <Grid item xs={11} sm={7}>
                                <VTextField
                                  fullWidth
                                  required
                                  size="small"
                                  name="number"
                                  label="Telfone Fixo"
                                  placeholder="(DDD) 1234-5678"
                                  disabled={isLoading}
                                  borderBottomLeft="0px"
                                  borderTopLeft="0px"
                                  formatData={formatPhone}
                                  inputProps={{ maxLength: 14 }}
                                />
                              </Grid>
                              {phones === 3 && (
                                <Box display="flex" flexDirection="row">
                                  <Tooltip title="adcionar telefone">
                                    <IconButton
                                      onClick={() => setPhones(phones + 1)}
                                    >
                                      <AddCircle color="success" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="remover telefone">
                                    <IconButton
                                      onClick={() => setPhones(phones - 1)}
                                    >
                                      <RemoveCircle color="error" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              )}
                            </Scope>
                          </Grid>
                        )}
                        {Number(phones) >= 4 && (
                          <Grid container item xs={12} justifyContent="center">
                            <Scope path="phones[3]">
                              <Grid item xs={11} sm={phones === 4 ? 4 : 5}>
                                <VTextField
                                  fullWidth
                                  required
                                  size="small"
                                  name="title"
                                  label="Nome"
                                  disabled={isLoading}
                                  borderBottomLeft="50px"
                                  borderTopLeft="50px"
                                  borderBottomRight="0px"
                                  borderTopRight="0px"
                                  inputProps={{ maxLength: 10 }}
                                />
                              </Grid>
                              <Grid item xs={11} sm={7}>
                                <VTextField
                                  fullWidth
                                  required
                                  size="small"
                                  name="number"
                                  label="Telfone Fixo"
                                  placeholder="(DDD) 1234-5678"
                                  disabled={isLoading}
                                  borderBottomLeft="0px"
                                  borderTopLeft="0px"
                                  formatData={formatPhone}
                                  inputProps={{ maxLength: 14 }}
                                />
                              </Grid>
                              {phones === 4 && (
                                <Box display="flex" flexDirection="row">
                                  <Tooltip title="adcionar telefone">
                                    <IconButton
                                      onClick={() => setPhones(phones + 1)}
                                    >
                                      <AddCircle color="success" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="remover telefone">
                                    <IconButton
                                      onClick={() => setPhones(phones - 1)}
                                    >
                                      <RemoveCircle color="error" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              )}
                            </Scope>
                          </Grid>
                        )}
                        {phones === 5 && (
                          <Grid container item xs={12} justifyContent="center">
                            <Scope path="phones[4]">
                              <Grid item xs={11} sm={phones === 5 ? 4 : 5}>
                                <VTextField
                                  fullWidth
                                  required
                                  size="small"
                                  name="title"
                                  label="Nome"
                                  disabled={isLoading}
                                  borderBottomLeft="50px"
                                  borderTopLeft="50px"
                                  borderBottomRight="0px"
                                  borderTopRight="0px"
                                  inputProps={{ maxLength: 10 }}
                                />
                              </Grid>
                              <Grid item xs={11} sm={7}>
                                <VTextField
                                  fullWidth
                                  required
                                  size="small"
                                  name="number"
                                  label="Telfone Fixo"
                                  placeholder="(DDD) 1234-5678"
                                  disabled={isLoading}
                                  borderBottomLeft="0px"
                                  borderTopLeft="0px"
                                  formatData={formatPhone}
                                  inputProps={{ maxLength: 14 }}
                                />
                              </Grid>
                              {phones === 5 && (
                                <Grid
                                  item
                                  xs={1}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <Tooltip title="remover telefone">
                                    <IconButton
                                      onClick={() => setPhones(phones - 1)}
                                    >
                                      <RemoveCircle color="error" />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              )}
                            </Scope>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid
                    container
                    item
                    justifyContent="center"
                    direction="column"
                    spacing={1}
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      width={smDown ? '99%' : '66%'}
                      borderRadius="10px"
                      padding={2}
                      gap={1}
                    >
                      <Typography variant="h4">Números de WhatsApp</Typography>
                      <Grid container item xs={12} justifyContent="center">
                        <Scope path="cellphones[0]">
                          <Grid item xs={11} sm={cellPhones === 1 ? 4 : 5}>
                            <VTextField
                              fullWidth
                              size="small"
                              name="title"
                              label="Nome"
                              disabled={isLoading}
                              borderBottomLeft="50px"
                              borderTopLeft="50px"
                              borderBottomRight="0px"
                              borderTopRight="0px"
                              inputProps={{ maxLength: 10 }}
                            />
                          </Grid>
                          <Grid item xs={11} sm={7}>
                            <VTextField
                              fullWidth
                              size="small"
                              name="number"
                              label="Número"
                              placeholder="(DDD) 9 1234-5678"
                              disabled={isLoading}
                              borderBottomLeft="0px"
                              borderTopLeft="0px"
                              inputProps={{ maxLength: 15 }}
                              formatData={formatCellPhone}
                            />
                          </Grid>
                          {cellPhones === 1 && (
                            <Grid
                              item
                              xs={1}
                              display="flex"
                              justifyContent="center"
                            >
                              <Tooltip title="adcionar celular">
                                <IconButton
                                  onClick={() => setCellPhones(cellPhones + 1)}
                                >
                                  <AddCircle color="success" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                        </Scope>
                      </Grid>
                      {Number(cellPhones) >= 2 && (
                        <Grid container item xs={12} justifyContent="center">
                          <Scope path="cellphones[1]">
                            <Grid item xs={11} sm={cellPhones === 2 ? 4 : 5}>
                              <VTextField
                                fullWidth
                                required
                                size="small"
                                name="title"
                                label="Nome"
                                disabled={isLoading}
                                borderBottomLeft="50px"
                                borderTopLeft="50px"
                                borderBottomRight="0px"
                                borderTopRight="0px"
                                inputProps={{ maxLength: 10 }}
                              />
                            </Grid>
                            <Grid item xs={11} sm={7}>
                              <VTextField
                                fullWidth
                                required
                                size="small"
                                name="number"
                                label="Número"
                                placeholder="(DDD) 9 1234-5678"
                                disabled={isLoading}
                                borderBottomLeft="0px"
                                borderTopLeft="0px"
                                inputProps={{ maxLength: 15 }}
                                formatData={formatCellPhone}
                              />
                            </Grid>
                            {cellPhones === 2 && (
                              <Box display="flex" flexDirection="row">
                                <Tooltip title="adcionar celular">
                                  <IconButton
                                    onClick={() =>
                                      setCellPhones(cellPhones + 1)
                                    }
                                  >
                                    <AddCircle color="success" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="remover celular">
                                  <IconButton
                                    onClick={() =>
                                      setCellPhones(cellPhones - 1)
                                    }
                                  >
                                    <RemoveCircle color="error" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            )}
                          </Scope>
                        </Grid>
                      )}
                      {Number(cellPhones) >= 3 && (
                        <Grid container item xs={12} justifyContent="center">
                          <Scope path="cellphones[2]">
                            <Grid item xs={11} sm={cellPhones === 3 ? 4 : 5}>
                              <VTextField
                                fullWidth
                                required
                                size="small"
                                name="title"
                                label="Nome"
                                disabled={isLoading}
                                borderBottomLeft="50px"
                                borderTopLeft="50px"
                                borderBottomRight="0px"
                                borderTopRight="0px"
                                inputProps={{ maxLength: 10 }}
                              />
                            </Grid>
                            <Grid item xs={11} sm={7}>
                              <VTextField
                                fullWidth
                                required
                                size="small"
                                name="number"
                                label="Número"
                                placeholder="(DDD) 9 1234-5678"
                                disabled={isLoading}
                                borderBottomLeft="0px"
                                borderTopLeft="0px"
                                inputProps={{ maxLength: 15 }}
                                formatData={formatCellPhone}
                              />
                            </Grid>
                            {cellPhones === 3 && (
                              <Box display="flex" flexDirection="row">
                                <Tooltip title="adcionar celular">
                                  <IconButton
                                    onClick={() =>
                                      setCellPhones(cellPhones + 1)
                                    }
                                  >
                                    <AddCircle color="success" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="remover celular">
                                  <IconButton
                                    onClick={() =>
                                      setCellPhones(cellPhones - 1)
                                    }
                                  >
                                    <RemoveCircle color="error" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            )}
                          </Scope>
                        </Grid>
                      )}
                      {Number(cellPhones) >= 4 && (
                        <Grid container item xs={12} justifyContent="center">
                          <Scope path="cellphones[3]">
                            <Grid item xs={11} sm={cellPhones === 4 ? 4 : 5}>
                              <VTextField
                                fullWidth
                                required
                                size="small"
                                name="title"
                                label="Nome"
                                disabled={isLoading}
                                borderBottomLeft="50px"
                                borderTopLeft="50px"
                                borderBottomRight="0px"
                                borderTopRight="0px"
                                inputProps={{ maxLength: 10 }}
                              />
                            </Grid>
                            <Grid item xs={11} sm={7}>
                              <VTextField
                                fullWidth
                                required
                                size="small"
                                name="number"
                                label="Número"
                                placeholder="(DDD) 9 1234-5678"
                                disabled={isLoading}
                                borderBottomLeft="0px"
                                borderTopLeft="0px"
                                inputProps={{ maxLength: 15 }}
                                formatData={formatCellPhone}
                              />
                            </Grid>
                            {cellPhones === 4 && (
                              <Box display="flex" flexDirection="row">
                                <Tooltip title="adcionar celular">
                                  <IconButton
                                    onClick={() =>
                                      setCellPhones(cellPhones + 1)
                                    }
                                  >
                                    <AddCircle color="success" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="remover celular">
                                  <IconButton
                                    onClick={() =>
                                      setCellPhones(cellPhones - 1)
                                    }
                                  >
                                    <RemoveCircle color="error" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            )}
                          </Scope>
                        </Grid>
                      )}
                      {cellPhones === 5 && (
                        <Grid container item xs={12} justifyContent="center">
                          <Scope path="cellphones[4]">
                            <Grid item xs={11} sm={cellPhones === 5 ? 4 : 5}>
                              <VTextField
                                fullWidth
                                required
                                size="small"
                                name="title"
                                label="Nome"
                                disabled={isLoading}
                                borderBottomLeft="50px"
                                borderTopLeft="50px"
                                borderBottomRight="0px"
                                borderTopRight="0px"
                                inputProps={{ maxLength: 10 }}
                              />
                            </Grid>
                            <Grid item xs={11} sm={7}>
                              <VTextField
                                fullWidth
                                required
                                size="small"
                                name="number"
                                label="Número"
                                placeholder="(DDD) 9 1234-5678"
                                disabled={isLoading}
                                borderBottomLeft="0px"
                                borderTopLeft="0px"
                                inputProps={{ maxLength: 15 }}
                                formatData={formatCellPhone}
                              />
                            </Grid>
                            {cellPhones === 5 && (
                              <Grid
                                item
                                xs={1}
                                display="flex"
                                justifyContent="center"
                              >
                                <Tooltip title="remover celular">
                                  <IconButton
                                    onClick={() =>
                                      setCellPhones(cellPhones - 1)
                                    }
                                  >
                                    <RemoveCircle color="error" />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            )}
                          </Scope>
                        </Grid>
                      )}
                    </Box>
                  </Grid>

                  <Grid
                    container
                    item
                    spacing={2}
                    display="flex"
                    justifyContent="center"
                  >
                    <Grid
                      item
                      xs={8}
                      sm={6}
                      display="flex"
                      justifyContent="center"
                    >
                      <FormControlLabel
                        label="Ouvidoria Automática"
                        control={<VSwitch name="notify_sectors" />}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      sm={6}
                      display="flex"
                      justifyContent="center"
                    >
                      <FormControlLabel
                        label="Ativar SMS"
                        control={
                          <VSwitch
                            name="activate_sms"
                            onChange={(_, e) => setSMS(e)}
                          />
                        }
                      />
                    </Grid>
                    {(sms || data?.activate_sms) && (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        display="flex"
                        justifyContent="center"
                      >
                        <VTextField
                          fullWidth
                          required
                          size="small"
                          name="sms_quantity"
                          type="number"
                          label="Quantidade de SMS"
                          borderBottomLeft="50px"
                          borderTopLeft="50px"
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>

            {/* Footer do Formulário */}
            <Box display="flex" flex={1} justifyContent="center">
              <Button type="submit">
                <Icon>save</Icon>
                Salvar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}
