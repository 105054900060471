/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, MutableRefObject } from 'react'
import {
  CircularProgress,
  Grid,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import { IProtocols } from '../../../../shared/services/api'
import { VForm, VSelect } from '../../../../shared/forms'
import { AutoComplete } from './AutoComplete'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import { Api } from '../../../../shared/services/api/axios-config'
import {
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { IModule, useAuthContext } from '../../../../shared/contexts'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { NoAccounts, NotificationsActive, Send } from '@mui/icons-material'
import { SecretModal } from './SecretModal'
import { useModuleContext } from '../../../../shared/contexts/ModulesContext'

interface IDetails {
  protocolData?: IProtocols
  external?: boolean
  update: MutableRefObject<boolean>
}

export const DetalhesSolicitacao: React.FC<IDetails> = ({
  protocolData,
  external = false,
  update,
}) => {
  const { user } = useAuthContext()

  const [isSending, setIsSending] = useState(false)
  const [sendingSurvey] = useState(false)
  const [change, setChange] = useState(false)
  const [notified, setNotified] = useState(true)

  const [secretModal, setSecretModal] = useState(false)

  const handleNotify = () => {
    Api.post(`/V1/protocols/${protocolData?.id}/notify/`, {
      protocol_id: protocolData?.id,
      notified_sector: true,
    })
      .then(() => {
        NotificacaoSucesso('Setor notificado com sucesso')
        if (update !== undefined) {
          setNotified(true)
          update.current = true
        }
      })
      .catch(() => {
        NotificacaoError('Erro ao notificar setor')
      })
  }

  const handleSubmit = (formData: any) => {
    setIsSending(true)
    Api.patch(`/V1/protocols/${protocolData?.id}/`, formData)
      .then(() => {
        setChange(false)
        setIsSending(false)
        NotificacaoSucesso('Alterações realizadas com sucesso')
      })
      .catch((error) => {
        setIsSending(false)
        console.log(error)
        NotificacaoError('Erro ao realizar alterações')
      })
  }

  useEffect(() => {
    if (protocolData?.notified_sector !== undefined)
      setNotified(protocolData.notified_sector)
    else setNotified(true)
  }, [protocolData])

  const { currentModule, moduleTitle } = useModuleContext()

  const [thisModule, setThisModule] = useState<IModule>()

  useEffect(() => {
    setThisModule(
      user?.modules.find(
        (mdl) => mdl.title === moduleTitle && mdl.type === currentModule,
      ),
    )
  }, [user?.modules, currentModule, moduleTitle])

  return (
    <Grid container spacing={2} direction="column">
      {!protocolData?.secret &&
        !protocolData?.anonymous &&
        thisModule?.profile === 2 &&
        !external && (
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<NoAccounts />}
              sx={{
                borderRadius: '50px',
              }}
              onClick={() => setSecretModal(true)}
            >
              Anonimizar
            </Button>
          </Grid>
        )}
      <Grid container item direction="row" spacing={1} xs={12}>
        {!notified && !external && (
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              sx={{ borderRadius: '50px' }}
              startIcon={<NotificationsActive />}
              onClick={() => handleNotify()}
            >
              Notificar Setor
            </Button>
          </Grid>
        )}
        <Grid item xs={2.4}>
          <Tooltip title="Dias Pendente">
            <Box
              bgcolor="#FFFF00"
              flex="1"
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
            >
              <Typography color="black" textAlign="center">
                {protocolData?.pending_days}
              </Typography>
            </Box>
          </Tooltip>
        </Grid>
        <Grid item xs={2.4}>
          <Tooltip title="Dias em Trâmite">
            <Box
              bgcolor="#CBAACB"
              flex="1"
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
            >
              <Typography color="black" textAlign="center">
                {protocolData?.analyzing_days}
              </Typography>
            </Box>
          </Tooltip>
        </Grid>
        <Grid item xs={2.4}>
          <Tooltip title="Dias em Atraso">
            <Box
              bgcolor="#EB3324"
              flex="1"
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
            >
              <Typography color="black" textAlign="center">
                {protocolData?.delayed_days}
              </Typography>
            </Box>
          </Tooltip>
        </Grid>
        <Grid item xs={2.4}>
          <Tooltip title="Dias Prorrogado">
            <Box
              bgcolor="#55CBCD"
              flex="1"
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
            >
              <Typography color="black" textAlign="center">
                {protocolData?.extended_days}
              </Typography>
            </Box>
          </Tooltip>
        </Grid>
        <Grid item xs={2.4}>
          <Tooltip title="Dias Em Recurso">
            <Box
              bgcolor="#7f440a"
              flex="1"
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
            >
              <Typography color="black" textAlign="center">
                {protocolData?.days_on_appeal}
              </Typography>
            </Box>
          </Tooltip>
        </Grid>
      </Grid>

      {(protocolData?.secret || protocolData?.anonymous) && (
        <Grid container item spacing={1}>
          <Grid item>
            <Typography>
              {protocolData.anonymous
                ? 'Manifestação anônima'
                : 'Manifestação sigilosa'}
            </Typography>
          </Grid>
        </Grid>
      )}

      {!external && (
        <Grid container item spacing={1}>
          <Grid item>
            <Typography>Setor notificado: </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {protocolData?.notified_sector ? 'Sim' : 'Não'}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Análise Iniciada: </Typography>
        </Grid>
        <Grid item>
          <Typography>
            {protocolData?.analysis_started ? 'Sim' : 'Não'}
          </Typography>
        </Grid>
      </Grid>

      {!protocolData?.anonymous && (
        <Grid container item spacing={1}>
          <Grid item>
            <Typography>Forma de resposta: </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {protocolData?.kind_response === 1
                ? 'E-mail'
                : protocolData?.kind_response === 2
                ? 'Telefone'
                : protocolData?.kind_response === 3
                ? 'Presencialmente'
                : 'Carta'}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Agrupamento: </Typography>
        </Grid>
        <Grid item>
          <Typography noWrap textOverflow="ellipsis">
            {protocolData?.grouping_id ? protocolData?.grouping_id?.name : ''}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Setor: </Typography>
        </Grid>
        <Grid item>
          <Typography>{protocolData?.sector_id?.name}</Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Assunto: </Typography>
        </Grid>
        <Grid item>
          <Typography>{protocolData?.subject_id?.name}</Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Tipologia: </Typography>
        </Grid>
        <Grid item>
          <Typography>
            {protocolData?.category_id ? protocolData?.category_id.name : ''}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>IP Local: </Typography>
        </Grid>
        <Grid item>
          {protocolData?.anonymous ? (
            <Typography>Anônimo</Typography>
          ) : (
            <>
              {protocolData?.secret ? (
                <>
                  {thisModule?.profile === 0 ? (
                    <Typography>
                      {protocolData?.ip || 'Não identificado'}
                    </Typography>
                  ) : (
                    <Typography>Sigilo</Typography>
                  )}
                </>
              ) : (
                <Typography>
                  {protocolData?.ip || 'Não identificado'}
                </Typography>
              )}
            </>
          )}
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>IP Externo: </Typography>
        </Grid>
        <Grid item>
          {protocolData?.anonymous ? (
            <Typography>Anônimo</Typography>
          ) : (
            <>
              {protocolData?.secret ? (
                <>
                  {thisModule?.profile === 0 ? (
                    <Typography>
                      {protocolData?.external_ip || 'Não identificado'}
                    </Typography>
                  ) : (
                    <Typography>Sigilo</Typography>
                  )}
                </>
              ) : (
                <Typography>
                  {protocolData?.external_ip || 'Não identificado'}
                </Typography>
              )}
            </>
          )}
        </Grid>
      </Grid>

      <Grid container item spacing={1}>
        <Grid item>
          <Typography>Endereço: </Typography>
        </Grid>
        <Grid item>
          {protocolData?.latitude ? (
            <Link
              href={`https://www.google.com.br/maps/search/${String(
                protocolData.latitude,
              )},${String(protocolData.longitude)}`}
              variant="body1"
              underline="hover"
              target="_blank"
              rel="noopener"
            >
              Abrir Mapa
            </Link>
          ) : (
            <Typography>Não Informado</Typography>
          )}
        </Grid>
      </Grid>

      {Number(protocolData?.status) > 4 && (
        <Grid container item spacing={1} direction="column">
          <Grid container item spacing={1}>
            <Grid item>
              <Typography>Respondeu a pesquisa de satisfação?</Typography>
            </Grid>
            <Grid item>
              <Typography>
                {protocolData?.replied_survey ? 'Sim' : 'Não'}
              </Typography>
            </Grid>
          </Grid>

          {!protocolData?.replied_survey &&
            protocolData?.external_user_id?.email &&
            !protocolData.anonymous && (
              <Grid container item spacing={1}>
                <Grid item xs={12}>
                  <Tooltip title="enviar e-mail com a pesquisa de satisfação para o manifestante">
                    <Button
                      disabled={sendingSurvey}
                      variant="outlined"
                      startIcon={
                        sendingSurvey ? (
                          <CircularProgress size={15} />
                        ) : (
                          <Send />
                        )
                      }
                    >
                      reenviar pesquisa
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            )}
        </Grid>
      )}
      <Grid item />

      {!!protocolData && !external ? (
        <>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <VForm onSubmit={handleSubmit} initialData={protocolData}>
                <Box display="flex" gap={2} flexDirection="column">
                  <AutoComplete
                    disabled={
                      thisModule?.profile !== 2 ||
                      Number(protocolData.status) > 4
                    }
                    institutionId={String(protocolData.institution_id)}
                    textStyle={{
                      fieldSet: {
                        borderRadius: '50px',
                      },
                    }}
                    onChange={() => setChange(true)}
                  />
                  <FormControl fullWidth sx={{ zIndex: 0 }}>
                    <InputLabel id="priority">Prioridade</InputLabel>
                    <VSelect
                      size="small"
                      name="priority"
                      labelId="priority"
                      label="Prioridade"
                      disabled={Number(protocolData.status) > 4}
                      myChange={() => setChange(true)}
                      sx={{
                        borderRadius: '50px',
                      }}
                    >
                      <MenuItem value={0}>Sem Prioridade</MenuItem>
                      <MenuItem value={1}>Baixa</MenuItem>
                      <MenuItem value={2}>Média</MenuItem>
                      <MenuItem value={3}>Alta</MenuItem>
                    </VSelect>
                  </FormControl>
                </Box>
                {change && (
                  <Box display="flex" flex="1" justifyContent="right">
                    <Button
                      type="submit"
                      sx={{ borderRadius: '50px' }}
                      disabled={isSending}
                    >
                      alterar
                    </Button>
                  </Box>
                )}
              </VForm>
            </Grid>
          </Grid>
        </>
      ) : (
        <>{!external && <Skeleton height="65px" width="100%" />}</>
      )}
      <SecretModal
        update={update}
        show={secretModal}
        onClose={() => setSecretModal(false)}
        protocolId={Number(protocolData?.id)}
      />
    </Grid>
  )
}
