import { useEffect, useState } from 'react'
import {
  TextareaAutosize,
  TextareaAutosizeProps,
  Typography,
  Box,
} from '@mui/material'
import { useField } from '@unform/core'

type TVTextAreaProps = TextareaAutosizeProps & {
  name: string
  minRows: number
  maxRows: number
  ariaLabel: string
  placeholder: string
}

export const VTextArea: React.FC<TVTextAreaProps> = ({
  name,
  minRows,
  maxRows,
  placeholder,
  ariaLabel,
  ...rest
}) => {
  const { fieldName, registerField, error, clearError, defaultValue } =
    useField(name)

  const [value, setValue] = useState(defaultValue || '')

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    })
  }, [registerField, fieldName, value])

  return (
    <Box display="flex" flex="1" flexDirection="column" alignItems="center">
      <TextareaAutosize
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          clearError()
        }}
        aria-label={ariaLabel}
        minRows={minRows}
        maxRows={maxRows}
        style={{
          ...rest.style,
          border: error ? '1px solid red' : rest.style?.border,
        }}
        placeholder={placeholder}
      />
      {error && (
        <Typography variant="caption" color="red">
          {error}
        </Typography>
      )}
    </Box>
  )
}
