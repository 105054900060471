/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  CardContent,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react'
import {
  VForm,
  VSelect,
  VSwitch,
  VTextArea,
  useVForm,
} from '../../../../shared/forms'
import { ModalMap } from '../ModalMap'
import { useDebounce } from '../../../../shared/hooks'
import {
  IClientDetail,
  IGroupings,
  createProtocol,
  reverseSearchAddress,
} from '../../../../shared/services/api'
import { Api } from '../../../../shared/services/api/axios-config'
import { useNavigate, useParams } from 'react-router-dom'
import { useExternalAuthContext } from '../../../../shared/contexts'
import {
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import {
  ArrowLeft,
  ArrowRight,
  DeleteForever,
  DeleteOutline,
  FileCopy,
  Map,
  Search,
} from '@mui/icons-material'
import {
  AutoCompleteAssunto,
  AutoCompleteSetor,
  AutoCompleteTipologia,
} from '../../components/AutoComplete'
import { formValidationSchema } from './validate'
import { ValidationError } from 'yup'

interface IFormProtocolo {
  clientIp: string | undefined
}

export const FormProtocolo: FC<IFormProtocolo> = ({ clientIp }) => {
  const navigate = useNavigate()
  const { formRef } = useVForm()
  const { slug } = useParams<'slug'>()
  const { debounce } = useDebounce(1000)
  const { user } = useExternalAuthContext()

  const theme = useTheme()

  const [isLoading, setIsLoading] = useState(true)

  const ssDown = useMediaQuery(theme.breakpoints.down(480))
  const lg2Down = useMediaQuery(theme.breakpoints.down(1600))

  const steps = ['Selecione Um Agrupamento', 'Manifestação']
  const [identification] = useState(1)
  const [groupings, setGroupings] = useState<IGroupings[]>()
  const [selectedGroup, setSelectedGroup] = useState<number>()
  const [attachments, setAttachments] = useState<any[]>([])
  const [totalFiles, setTotalFiles] = useState(0)
  const [selectedSector, setSelectedSector] = useState<number>()
  const [search, setSearch] = useState('')
  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = useState(new Set<number>())
  const [mapOpen, setMapOpen] = useState(false)
  const [data, setData] = useState<IClientDetail>()
  const lat = useRef<number | undefined>(undefined)
  const lng = useRef<number | undefined>(undefined)

  let testFile: any[] = []

  const mapClose = () => {
    setMapOpen(false)
  }

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    setIsLoading(true)
    setSearch('')
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const protocolData = new FormData()

  const handleSubmit = (formData: any) => {
    formData.institution_id = data?.id
    formData.grouping_id = selectedGroup
    formData.external_ip = clientIp
    formData.user_history_type = 2
    if (!!lat.current && identification !== 3) formData.latitude = lat.current
    if (!!lng.current && identification !== 3) formData.longitude = lng.current

    Object.keys(formData).forEach((data: any) => {
      protocolData.append(data, formData[data])
    })
    if (attachments.length > 0) {
      for (let index = 0; index < attachments.length; index++) {
        const element = attachments[index]
        formData[`file${index + 1}`] = element
        protocolData.append(`file${index + 1}`, element, element.name)
      }
    }
    formValidationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        createProtocol(protocolData, user.token).then((result) => {
          if (result instanceof Error) {
            console.error(result)
            NotificacaoError(result.message)
          } else {
            formRef.current?.reset()
            NotificacaoSucesso('Manifestação Aberta com Sucesso')
            navigate(`/ouvidoria/${slug}/protocolos/detalhes/${result.id}`)
          }
        })
      })
      .catch((errors: ValidationError) => {
        const validationErrors: { [key: string]: string } = {}

        errors.inner.forEach((error) => {
          if (!error.path) return

          validationErrors[error.path] = error.message
        })
        formRef.current?.setErrors(validationErrors)
      })
  }

  useEffect(() => {
    setIsLoading(true)
    if (activeStep === 0 && data?.id) {
      debounce(() => {
        Api.get(`/V1/externalusers/groupings/${data.id}/?status=true`).then(
          (result) => {
            setIsLoading(false)
            if (result instanceof Error) {
              console.error(result)
            } else {
              setGroupings(result.data)
            }
          },
        )
      })
    }
  }, [search, activeStep, data])

  useEffect(() => {
    Api.get(`/V1/institution/${slug}/`).then((result) => {
      if (result instanceof Error) {
        NotificacaoError(result.message)
      } else {
        setData(result.data)
      }
    })
  }, [])

  useEffect(() => {
    if (lat.current !== undefined && lng.current !== undefined) {
      reverseSearchAddress(lat.current, lng.current).then((result) => {
        if (result instanceof Error) {
          NotificacaoError(result.message)
        }
      })
    }
  }, [lat.current, lng.current])

  return (
    <Box>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}
          if (isStepSkipped(index)) {
            stepProps.completed = false
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>
                {activeStep === index ? label : ''}
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
      {ModalMap(mapClose, mapOpen, lat, lng)}
      <VForm ref={formRef} onSubmit={handleSubmit}>
        <Grid
          container
          direction="column"
          spacing={2}
          display="flex"
          justifyContent="center"
          paddingX={3}
          paddingTop={3}
        >
          {activeStep === 0 ? (
            <>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Search sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  fullWidth
                  margin="dense"
                  label="Buscar Agrupamento..."
                  value={search}
                  variant="standard"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Box>
              <Typography paddingTop={1}>
                {selectedGroup !== undefined
                  ? `Agrupamento Selecionado: ${groupings?.find(
                      (group) => group.id === selectedGroup,
                    )?.name}`
                  : 'Selecione um agrupamento'}
              </Typography>
              {isLoading && (
                <Grid item>
                  <LinearProgress
                    variant="indeterminate"
                    sx={{ height: '5px', borderRadius: '50px' }}
                  />
                </Grid>
              )}
              <Grid container item spacing={3} marginTop={1}>
                {groupings?.map((group) => (
                  <Grid
                    key={group.id}
                    item
                    xs={ssDown ? 6 : 3}
                    md={2}
                    lg={lg2Down ? 2 : 1}
                  >
                    <Card
                      sx={
                        selectedGroup === group.id
                          ? {
                              maxHeight: '200px',
                              width: '100%',
                              border: '1px solid dodgerblue',
                              position: 'relative',
                              top: '-20px',
                              animation: 'go-back 1s infinite alternate',
                            }
                          : {
                              maxHeight: '200px',
                              width: '100%',
                              ':hover': {
                                border: '1px solid dodgerblue',
                                position: 'relative',
                                top: '-20px',
                                animation: 'go-back 1s infinite alternate',
                              },
                            }
                      }
                      component={Button}
                      elevation={10}
                      onClick={() => {
                        if (selectedGroup === group.id) {
                          handleNext()
                        } else {
                          setSelectedGroup(group.id)
                        }
                      }}
                    >
                      <CardContent>
                        <Box
                          flex={1}
                          display="flex"
                          alignItems="center"
                          flexDirection="column"
                          justifyContent="center"
                          gap={1}
                        >
                          <img width="70%" src={group.icon} alt="icon" />
                          <Typography
                            variant="caption"
                            fontSize={15}
                            flexWrap="nowrap"
                            textOverflow="ellipsis"
                          >
                            {group.name}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <Grid container item direction="column" spacing={2} padding={1}>
              <Grid item xs={12}>
                <Typography paddingTop={1}>
                  {selectedGroup !== undefined
                    ? `Agrupamento Selecionado: ${groupings?.find(
                        (group) => group.id === selectedGroup,
                      )?.name}`
                    : 'Selecione um agrupamento'}
                </Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={<VSwitch name="secret" />}
                  label="Sigilo?"
                />
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={identification === 3}
                    onClick={() => setMapOpen(true)}
                    startIcon={<Map />}
                    sx={{ borderRadius: '50px' }}
                  >
                    Local do ocorrido
                  </Button>
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <AutoCompleteSetor
                    external
                    institutionId={Number(data?.id)}
                    groupingId={selectedGroup}
                    onChange={(e) => setSelectedSector(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="kind-response-label">
                      Forma de Resposta
                    </InputLabel>
                    <VSelect
                      disabled={identification === 3}
                      sx={{ borderRadius: '50px' }}
                      size="small"
                      name="kind_response"
                      fullWidth
                      labelId="kind-response-label"
                      label="Forma de Resposta"
                      useDefaultValue={false}
                      placeholder="Selecione como deseja ser respondido"
                    >
                      <MenuItem value={1}>Email</MenuItem>
                      <MenuItem value={2}>Telefone</MenuItem>
                      <MenuItem value={3}>Pessoalmente</MenuItem>
                      <MenuItem value={4}>Carta</MenuItem>
                    </VSelect>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <AutoCompleteAssunto
                    sectorId={selectedSector || undefined}
                    institutionId={Number(data?.id)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AutoCompleteTipologia institutionId={Number(data?.id)} />
                </Grid>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <VTextArea
                  name="description"
                  minRows={4}
                  maxRows={8}
                  placeholder="Descreva aqui sua manifestação"
                  ariaLabel="Descrição"
                  style={{
                    width: '90%',
                    borderRadius: '5px',
                    fontVariant: 'historical-forms',
                    font: 'message-box',
                  }}
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                display="flex"
                justifyContent="center"
                direction="column"
              >
                <Grid container item xs={12} direction="column" spacing={2}>
                  {attachments.map((file, index) => (
                    <Grid
                      key={index}
                      item
                      direction="row"
                      gap={1}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography>{file.name}</Typography>
                      <Tooltip title="Remover este arquivo">
                        <IconButton
                          onClick={() => {
                            setAttachments(
                              attachments.filter(
                                (_, thisIndex) => thisIndex !== index,
                              ),
                            )
                            setTotalFiles(totalFiles - 1)
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  ))}
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    gap={1}
                  >
                    <Button
                      variant="contained"
                      component="label"
                      disableElevation
                      sx={{ borderRadius: '50px' }}
                      startIcon={<FileCopy />}
                      disabled={attachments.length === 5}
                    >
                      Anexar Arquivo
                      <input
                        hidden
                        multiple
                        max={5}
                        type="file"
                        onChange={(e) => {
                          if (e.target.files) {
                            if (
                              e.target.files.length + attachments.length >
                              5
                            ) {
                              return NotificacaoError(
                                'Número máximo de arquivos permitidos: 5',
                              )
                            }
                            for (
                              let index = 0;
                              index < e.target.files.length;
                              index++
                            ) {
                              const element = e.target.files[index]
                              testFile.push(element)
                              setAttachments([...attachments, ...testFile])
                              setTotalFiles(totalFiles + 1)
                            }
                          } else {
                            setTotalFiles(0)
                            testFile = []
                            setAttachments(testFile)
                          }
                        }}
                      />
                    </Button>
                    {attachments.length > 0 && (
                      <Tooltip title="Remover tudo">
                        <IconButton
                          onClick={() => {
                            setTotalFiles(0)
                            setAttachments([])
                            testFile = []
                          }}
                        >
                          <DeleteForever />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Button
                  type="submit"
                  variant="outlined"
                  sx={{ borderRadius: '50px' }}
                >
                  Enviar
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </VForm>
      {activeStep === 0 && (
        <Fab
          color="primary"
          aria-label="add"
          size="large"
          style={{
            position: 'absolute',
            right: '5%',
            top: '85%',
            zIndex: 1,
          }}
          disabled={!selectedGroup && activeStep === 0}
          onClick={handleNext}
        >
          <ArrowRight />
        </Fab>
      )}
      {activeStep === 1 && (
        <Fab
          color="primary"
          aria-label="add"
          size="large"
          style={{
            position: 'absolute',
            left: '5%',
            top: '85%',
            zIndex: 1,
          }}
          disabled={activeStep === steps.length}
          onClick={handleBack}
        >
          <ArrowLeft />
        </Fab>
      )}
    </Box>
  )
}
