/* eslint-disable @typescript-eslint/ban-types */
import { Api } from '../axios-config'

export interface SacModuleList {
  id: number
  slug: string
  status: boolean
  sac_title: string
}

export interface SacModule {
  id: number
  status: boolean
  sac_plan: 1 | 2
  sac_slug: string
  sac_logo: string
  sac_title: string
  sac_email: string
  sac_price: number
  sac_prefix: string
  created_at: string
  updated_at: string
  institution: number
  sac_address: string
  sac_separator: string
  sac_limit_days: number
  sac_expires_at: string
  sac_responsible: string
  sac_extend_days: number
  sac_sms_consumed: number
  sac_working_hour: string
  sac_sms_quantity: number
  sac_activate_sms: boolean
  sac_phones: Record<string, string>[]
  sac_cellphones: Record<string, string>[]
}

interface TotalCount {
  data: SacModule[]
  count: number
  next: string
  previous: string
  results: SacModule[]
}

const getAllSacs = async (
  search = '',
  limit = '0',
  item = '10',
  institution = '',
): Promise<TotalCount | Error> => {
  try {
    const relativeUrl = `/V1/sac/`

    const { data } = await Api.get(relativeUrl, {
      params: {
        limit,
        item,
        search,
        institution,
      },
    })

    if (data) return data

    return new Error('Erro ao carregar instâncias')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const getSacById = async (
  sacId: number | string,
): Promise<SacModule | Error> => {
  try {
    const relativeUrl = `/V1/sac/${sacId}/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data
    return new Error('Erro ao carregar instância')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const createSac = async (formData: {}): Promise<SacModule | Error> => {
  try {
    const relativeUrl = '/V1/sac/'

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao criar instância')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const updateSacById = async (
  id: number,
  formData: {},
): Promise<SacModule | Error> => {
  try {
    const relativeUrl = `/V1/sac/${id}/`

    const { data } = await Api.patch(relativeUrl, formData)

    if (data) return data
    return new Error('Erro ao atualizar instância')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export { updateSacById, getAllSacs, getSacById, createSac }
