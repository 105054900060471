import { Api } from '../axios-config'

export interface IReports {
  [key: string]: {
    [key: string]: string | number
  }
}

export interface IGraphic {
  id: string
  label: string
  value: number
}

const getReport = async (formData: {
  initial: string
  final: string
  period: number
  type: number
  sector_id?: number
  grouping_id?: number
  subject_id?: number
  category_id?: number
  kind_response?: number
}): Promise<IReports | Error> => {
  try {
    const relativeUrl = `/V1/reports/`

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data
    return new Error('Erro ao gerar relatório')
  } catch (error) {
    console.log(error)
    return new Error((error as { message: string }).message)
  }
}

const getSituation = async (
  institutionId: number,
  year: number,
): Promise<IGraphic[] | Error> => {
  try {
    const relativeUrl = `/V1/externalusers/situation_data/${institutionId}/${year}/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar informações')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const getGender = async (
  institutionId: number,
  year: number,
): Promise<IGraphic | Error> => {
  try {
    const relativeUrl = `/V1/externalusers/gender_data/${institutionId}/${year}/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar informações')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const getEscolarity = async (
  institutionId: number,
  year: number,
): Promise<IGraphic[] | Error> => {
  try {
    const relativeUrl = `/V1/externalusers/escolarity_data/${institutionId}/${year}/`

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar informações')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export { getGender, getReport, getSituation, getEscolarity }
