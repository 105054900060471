import { useState, useEffect, FC, MutableRefObject } from 'react'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  LinearProgress,
  Modal,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import {
  VForm,
  useVForm,
  VTextField,
  VSwitch,
  VTextArea,
} from '../../../../shared/forms'
import {
  IQuestion,
  createQuestion,
  updateQuestionById,
} from '../../../../shared/services/api'
import { useAuthContext } from '../../../../shared/contexts'
import { ModalHeader } from '../../../../shared/components/modal/Header'
import { useModuleContext } from '../../../../shared/contexts/ModulesContext'

interface IFormData {
  id?: string
  title: string
  status: boolean
  introduction?: string
  institution_id: number
}

// const formValiidationSchema: yup.SchemaOf<IFormData> = yup.object({
//   id: yup.string(),
//   introduction: yup.string().max(1000),
//   title: yup.string().required().min(3).max(255),
//   status: yup.boolean().required().default(false),
//   institution_id: yup.number().required().default(1),
// })

interface IFormPerguntasProps {
  show: boolean
  create: boolean
  data?: IQuestion
  onClose: () => void
  update: MutableRefObject<boolean>
}

export const FormPerguntas: FC<IFormPerguntasProps> = ({
  data,
  show,
  create,
  update,
  onClose,
}) => {
  const { user } = useAuthContext()

  const [formTitle, setFormTitle] = useState('')
  const { formRef } = useVForm()

  const [isLoading, setIsLoading] = useState(false)

  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const { moduleTitle } = useModuleContext()

  const handleSubmit = (formData: IFormData) => {
    setIsLoading(true)

    if (create) {
      const currentModule = user?.modules.find(
        (mdl) => mdl.type === 'ombudsman' && mdl.title === moduleTitle,
      )
      if (currentModule?.profile === 0) {
        if (!localStorage.getItem('ADM_SELECTED_INSTITUTION')) {
          NotificacaoError(
            'Selecione Uma Instituição Antes de Criar uma Nova instância',
          )
          setIsLoading(false)
          return onClose()
        }
        formData.institution_id = Number(
          localStorage.getItem('ADM_SELECTED_INSTITUTION'),
        )
      } else {
        formData.institution_id = Number(user?.institution_id)
      }
    } else {
      formData.institution_id = Number(data?.institution_id)
    }

    create
      ? createQuestion(formData).then((result) => {
          setIsLoading(false)

          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            update.current = true
            onClose()
            NotificacaoSucesso('Pergunta Criada com Sucesso')
          }
        })
      : updateQuestionById(Number(data?.id), formData).then((result) => {
          setIsLoading(false)
          if (result instanceof Error) {
            NotificacaoError(result.message)
          } else {
            update.current = true
            onClose()
            NotificacaoSucesso('Pergunta Atualizada com Sucesso')
          }
        })
  }

  useEffect(() => {
    if (!create && data !== undefined)
      setFormTitle(`Editar Pergunta ${data.title}`)
    else setFormTitle('Nova Pergunta')
  }, [create, data, show])

  return (
    <Modal onClose={() => onClose()} open={show} aria-describedby="modal-title">
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        width={
          xsDown
            ? theme.spacing(30)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader onClose={onClose} showCloseButton title={formTitle} />

        <Divider />
        {isLoading && (
          <LinearProgress
            variant="indeterminate"
            sx={{ height: '5px', borderRadius: '50px', mt: 1 }}
          />
        )}
        <Box margin={1}>
          <VForm
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={create ? undefined : data}
          >
            <Grid container direction="column" spacing={2} padding={2}>
              <Grid container item>
                <Grid item xs={12}>
                  <VTextArea
                    name="introduction"
                    maxLength={1000}
                    minRows={4}
                    maxRows={8}
                    placeholder="*INTRODUÇÃO OPCIONAL*
Texto visível antes da pergunta"
                    ariaLabel="Descrição"
                    style={{
                      width: '100%',
                      borderRadius: '5px',
                      fontVariant: 'historical-forms',
                      font: 'message-box',
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="title"
                    label="Pergunta"
                    borderBottomLeft="50px"
                    borderTopLeft="50px"
                    inputProps={{ maxLength: 255 }}
                  />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center">
                  <FormControlLabel
                    control={<VSwitch name="status" />}
                    label="Status"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box display="flex" flex={1} justifyContent="center" gap={1}>
              <Button type="submit" variant="contained" disableElevation>
                <Icon>save</Icon>
                Salvar
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  formRef.current?.reset()
                  onClose()
                }}
              >
                cancelar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}
