import { Box, Button, Divider, Modal, Paper, Typography } from '@mui/material'
import { FC, MutableRefObject } from 'react'
import {
  FormBoxStyle,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../../shared/components'
import { Api } from '../../../../shared/services/api/axios-config'

interface ISecretModalProps {
  show: boolean
  onClose: () => void
  protocolId: number
  update: MutableRefObject<boolean>
}

export const SecretModal: FC<ISecretModalProps> = ({
  show,
  onClose,
  protocolId,
  update,
}) => {
  const handleSubmit = () => {
    Api.post('/V1/protocols/set_secret/edit/', { protocol_id: protocolId })
      .then(() => {
        NotificacaoSucesso('Anonimização realizada com sucesso')
        update.current = true
        onClose()
      })
      .catch((error) => {
        NotificacaoError(error.message)
      })
  }

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        sx={FormBoxStyle}
        component={Paper}
        width="300px"
        maxHeight="85%"
        borderRadius="20px"
      >
        <Typography
          textAlign="center"
          variant="h6"
          sx={{
            color: 'yellow',
          }}
        >
          ATENÇÃO
        </Typography>
        <Divider />
        <Box marginTop={2}>
          <Typography textAlign="justify" paragraph>
            Essa é uma ação irreversível. Após a confirmação, a manifestação
            será tratada como sigilosa e apenas o ouvidor poderá ver os dados
            relacionados ao manifestante.
          </Typography>
          <Typography textAlign="center">Deseja continuar?</Typography>
        </Box>
        <Box
          display="flex"
          flex="1"
          justifyContent="center"
          gap={1}
          paddingTop={1}
        >
          <Button
            variant="contained"
            disableElevation
            sx={{
              borderRadius: '50px',
            }}
            onClick={handleSubmit}
          >
            confirmar
          </Button>
          <Button onClick={onClose} sx={{ borderRadius: '50px' }}>
            cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
