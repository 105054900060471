import { useEffect, useState } from 'react'
import { Autocomplete, SxProps, TextField, Theme } from '@mui/material'
import { useField } from '@unform/core'

type TAutoCompleteOptions = {
  id: string
  label: string
}

interface IAutoComplet {
  textStyle?: SxProps<Theme>
  onChange?: (e: TAutoCompleteOptions[]) => void
}

export const MultiSelect: React.FC<IAutoComplet> = ({
  textStyle,
  onChange,
}) => {
  const { fieldName, registerField, error, clearError, defaultValue } =
    useField('modules')
  const [options, setOptions] = useState<TAutoCompleteOptions[]>([])
  const [search, setSearch] = useState('')
  const [selectedOptions, setSelectedOptions] = useState<
    TAutoCompleteOptions[]
  >([])
  const [value, setValue] = useState<string[] | null>(defaultValue || null)
  const [firstTime, setFirstTime] = useState(true)

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    })
  }, [registerField, fieldName, value])

  useEffect(() => {
    setOptions([
      { id: 'ci', label: 'Comunicação Interna' },
      { id: 'ombudsman', label: 'Ouvidoria' },
    ])
  }, [search])

  useEffect(() => {
    setValue(selectedOptions.map((option) => option.id))
  }, [selectedOptions, firstTime])

  useEffect(() => {
    setFirstTime(false)
    if (defaultValue) {
      setSelectedOptions(options.filter((opt) => defaultValue.includes(opt.id)))
    }
  }, [defaultValue, firstTime, options])

  return (
    <Autocomplete
      fullWidth
      multiple
      value={selectedOptions}
      limitTags={2}
      id="multiple-limit-tags"
      options={options}
      getOptionDisabled={(option) =>
        !!selectedOptions.find((opt) => option.id === opt.id)
      }
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      onChange={(_, value) => {
        setSelectedOptions(value)
        onChange?.(value)
      }}
      defaultValue={selectedOptions}
      onInputChange={(_, value) => setSearch(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="Módulos"
          size="small"
          sx={textStyle}
          error={!!error}
          helperText={error}
          onKeyDown={error ? clearError : undefined}
        />
      )}
    />
  )
}
