import { useNavigate, useParams } from 'react-router-dom'
import { LayoutBaseDePagina } from '../../../shared/layouts'
import {
  FerramentasDeDetalhes,
  LegendaSumario,
  NotificacaoError,
} from '../../../shared/components'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'

import { FormTipologia } from './formulario/Formulário'
import { ITypologies, getTypologyById } from '../../../shared/services/api'
import { ExpandMore } from '@mui/icons-material'
import { FormatDateTime, FormatStatus } from '../../../shared/utils'
import { TypologyHistory } from './components/TypolpogyHistory'
import {
  ITypologyHistory,
  getTypologyHistory,
} from '../../../shared/services/api/histories-services/TypologiesHistoryServices'

export const DetalhesTipologia: React.FC = () => {
  const navigate = useNavigate()

  const updated = useRef(false)

  const { id = 'new' } = useParams<'id'>()

  const [data, setData] = useState<ITypologies>()
  const [viewForm, setViewForm] = useState(false)
  const [create, setCreate] = useState(true)

  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')

  const [history, setHistory] = useState<ITypologyHistory[]>([])
  const [historyLoading, setHistoryLoading] = useState(true)

  const handleViewForm = () => {
    setViewForm(!viewForm)
    setCreate(true)
  }

  useEffect(() => {
    if (id !== 'nova') {
      setIsLoading(true)

      Promise.all([
        getTypologyById(Number(id)),
        getTypologyHistory(Number(id)),
      ]).then((values) => {
        setIsLoading(false)
        setHistoryLoading(false)
        updated.current = false

        if (values[0] instanceof Error) {
          NotificacaoError(values[0].message)
          navigate(-1)
        } else {
          setName(values[0].name)
          setData(values[0])
        }

        if (values[1] instanceof Error) {
          NotificacaoError(values[1].message)
        } else {
          setHistory(values[1])
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, updated.current])

  return (
    <LayoutBaseDePagina
      title={`Tipologia ${name}` || 'Detalhes Tipologia'}
      barraDeFerramentas={
        <FerramentasDeDetalhes
          textoBotaoNovo="Novo"
          mostrarBotaoEditar={!data?.esic && !data?.compliance && !isLoading}
          aoClicarEmNovo={() => {
            setCreate(true)
            setViewForm(true)
          }}
          aoClicarEmEditar={() => {
            setCreate(false)
            setViewForm(true)
          }}
          aoClicarEmVoltar={() => navigate(-1)}
          mostrarBotaoApagar={false}
        />
      }
    >
      <Box margin={1}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <LegendaSumario icon="info_outlined" name="Informações" />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} direction="column">
              {isLoading && (
                <Grid item>
                  <LinearProgress
                    variant="indeterminate"
                    sx={{
                      borderRadius: '50px',
                      height: '5px',
                    }}
                  />
                </Grid>
              )}
              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Status: </Typography>
                </Grid>
                <Grid item width="75px">
                  {FormatStatus(Boolean(data?.status))}
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Nome: </Typography>
                </Grid>
                <Grid item>
                  <Typography>{data?.name}</Typography>
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Criada em: </Typography>
                </Grid>
                <Grid item>
                  <Typography>{FormatDateTime(data?.created_at)}</Typography>
                </Grid>
              </Grid>

              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>Última atualização em: </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {data?.created_at !== data?.updated_at
                      ? FormatDateTime(data?.updated_at)
                      : 'Não houve alterações'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <LegendaSumario icon="restore" name="Histórico" />
          </AccordionSummary>
          <AccordionDetails>
            <TypologyHistory
              history={history}
              historyLoading={historyLoading}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
      <FormTipologia
        create={create}
        show={viewForm}
        update={updated}
        onClose={handleViewForm}
        data={!create ? data : undefined}
      />
    </LayoutBaseDePagina>
  )
}
