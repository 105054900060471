/* eslint-disable @typescript-eslint/ban-types */
import { Api } from '../axios-config/index'

export interface IClientList {
  id: string
  name: string
  created_at: string
  ci_expires_at: string
  ombudsman_expires_at: string
  status: boolean
  modules: ['ombudsman' | 'ci' | 'ombudsman', 'ci' | null]
}

interface IPhones {
  title: string
  number: string
}

interface IAnswers {
  title: string
}

interface IWorkField {
  id: number
  label: string
}

export interface IClientView {
  id: number
  logo: string
  name: string
  slug: string
  modules: string[]
  phones: IPhones[]
  contact_name: string
  cellphones: IPhones[]
  ombudsman_email: string
  ombudsman_title: string
}

export interface IClientDetail {
  id: string
  name: string
  reports: boolean
  separator_ci: string
  separator_ov: string
  general_informations: boolean
  created_at: string
  ci_expires_at: string
  ombudsman_expires_at: string
  status: boolean
  cnpj: string
  ombudsman_email: string
  anonymous: boolean
  slug: string
  prefix: string
  logo: string
  ombudsman_price: string
  ci_price: string
  client_type: number
  ombudsman_plan: number
  contact_name: string
  address: string
  modules: [key: string]
  coverage: number
  ombudsman_limit_days: number
  ci_limit_days: number
  ci_extended_days: number
  ombudsman_title: string
  working_hour: string
  phones: IPhones[]
  cellphones: IPhones[]
  notify_sectors: boolean
  activate_sms: boolean
  sms_quantity: number
  sms_consumed: number
  default_replies: IAnswers[]
  work_field: IWorkField
  slides?: string[]
  states: [key: number]
  cities: [key: number]
}

type IClientTotalCount = {
  data: IClientList[]
  count: number
  next: string
  previous: string
  results: IClientList[]
}

const getAllClients = async (
  item = '0',
  total = '10',
  filter = '',
): Promise<IClientTotalCount | Error> => {
  try {
    const relativeUrl = `/V1/institutions/`

    const { data } = await Api.get(relativeUrl, {
      params: {
        item,
        total,
        search: filter,
      },
    })

    if (data) {
      return {
        data,
        results: data.results,
        count: data.count,
        next: data.next,
        previous: data.previous,
      }
    }

    return new Error('Erro ao listar registros')
  } catch (error) {
    console.error(error)
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros',
    )
  }
}

const getClientById = async (id: number): Promise<IClientDetail | Error> => {
  try {
    const relativeUrl = `/V1/institutions/${id}/`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return data
    }

    return new Error('Erro ao Consultar Registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao Consultar Registro',
    )
  }
}

const createClient = async (
  formData: IClientDetail,
): Promise<IClientDetail | Error> => {
  try {
    const relativeUrl = '/V1/institutions/'

    const { data } = await Api.post(relativeUrl, formData)

    if (data) {
      return data
    }

    return new Error('Erro ao Criar Registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao Criar Registro',
    )
  }
}

const updateClientById = async (
  id: number,
  formData: {},
): Promise<IClientDetail | Error> => {
  try {
    const relativeUrl = `/V1/institutions/${id}/`

    const { data } = await Api.patch(relativeUrl, formData)

    if (data) {
      return data
    }

    return new Error('Erro ao Atualizar Registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao Atualizar Registro',
    )
  }
}

const selectClient = async (
  state: number,
  search?: string,
): Promise<IClientView[] | Error> => {
  try {
    const relativeUrl = `/V1/externalusers/institutions/${state}/all/?search=${search}`

    const { data } = await Api.get(relativeUrl)

    if (data) {
      return data
    }

    return new Error('Erro ao Listar Instituições')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao Listar Instituições',
    )
  }
}

export {
  getAllClients,
  getClientById,
  updateClientById,
  createClient,
  selectClient,
}
